// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'user',
    path: '/dashboard/user',
    icon: icon('ic_user'),
  },
  {
    title: 'product',
    path: '/dashboard/product',
    icon: icon('ic_product'),
  },
  {
    title: 'IMEI',
    path: '/dashboard/imei',
    icon: icon('ic_imei'),
  },
  {
    title: 'Iphone 15 Device',
    path: '/dashboard/device',
    icon: icon('ic_device'),
  },
  {
    title: 'Search All Device',
    path: '/dashboard/search_device',
    icon: icon('ic_device'),
  },
  {
    title: 'Download Data',
    path: '/dashboard/download_data',
    icon: icon('ic_download'),
  },
  {
    title: 'Purchased IMEI',
    path: '/dashboard/purchased_imei',
    icon: icon('ic_imei'),
  },
  {
    title: 'Mac Device',
    path: '/dashboard/mac',
    icon: icon('ic_mac'),
  },
  {
    title: 'Mac Serial Number',
    path: '/dashboard/mac_serial_number',
    icon: icon('ic_imei'),
  }
];

export default navConfig;
