import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
// @mui
import { Container, Stack, Typography, Grid, Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';
import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc, serverTimestamp, Timestamp, getDocs, collection  } from "firebase/firestore";
import { getDatabase, ref as dbref, runTransaction, set, child, get, update } from "firebase/database";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import moment from 'moment';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const firebaseConfig = {
  apiKey: "AIzaSyAyABStYrlc_6qxnXXH9hUAP5_4-xasdfI",
  authDomain: "genxt-9f006.firebaseapp.com",
  databaseURL: "https://genxt-9f006-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "genxt-9f006",
  storageBucket: "genxt-9f006.appspot.com",
  messagingSenderId: "566837765472",
  appId: "1:566837765472:web:1d768d0f53e6e747302a05"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
const rtdb = getDatabase(app);

// ----------------------------------------------------------------------

export default function SearchRegistration() {

  const [loading, setLoading] = React.useState(false);

  const [emailError, setEmailError] = React.useState(false);
  const [imeiError, setImeiError] = React.useState(false);

  const [regId, setRegId] = React.useState('');
  const [regCountry, setRegCountry] = React.useState('');
  const [regfullname, setRegfullname] = React.useState('');
  const [regStatus, setRegStatus] = React.useState('');
  const [regEWExpiredDate, setRegEWExpiredDate] = React.useState('');
  const [regEWCoverDate, setRegEWCoverDate] = React.useState('');
  const [regSCExpiredDate, setRegSCExpiredDate] = React.useState('');
  const [regSCCoverDate, setRegSCCoverDate] = React.useState('');

  const [open, setOpen] = React.useState(false);

  const [category, setCategory] = React.useState('iphone');

  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let imeiPass = false;
    let emailPass = false;

    const data = new FormData(event.currentTarget);

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if(data.get('email').length > 0 && emailRegex.test(data.get('email'))){
      setEmailError(false)
      emailPass = true;
    }
    else
    {
      setEmailError(true)
    }

    if(data.get('imeiNo').length > 12){
      setImeiError(false)
      imeiPass = true;
    }
    else
    {
      setImeiError(true)
    }

    if(emailPass === true && imeiPass === true){
      
      setLoading(true)

      const arr = [];
      const allemails = [];
      let emailFound = false;
      let emailRecord = null;

      let imeiFound = false;
      let imeiRecord = null;

      get(child(dbref(rtdb), `device-registration`)).then((snapshot) => {
        if (snapshot.exists()) {

          snapshot.forEach((doc) => {

            arr.push({
              ...doc.val(),
              id: doc.key,
            });

          })

          arr.forEach((val)=>{

            if(val.email.toString().toLowerCase() === data.get('email').toLowerCase()){
              emailFound = true;
              emailRecord = val;
              allemails.push(val)
            }
    
            if(Number(val.imeino) === Number(data.get('imeiNo').toLowerCase())){
              
              imeiFound = true;
              imeiRecord = val;
            }
          })
    
  
          if(emailFound)
          {
            setEmailError(false)
          }
          else{
            setEmailError(true)
            setLoading(false)
          }
    
          if(imeiFound)
          {
            setImeiError(false)
          }
          else{
            setImeiError(true)
            setLoading(false)
          }

          if(allemails.length > 0)
          {
            let tallyRecord = false;
            allemails.forEach(val =>{
              if(val.id === imeiRecord.id)
              {
                tallyRecord = true;
                emailRecord = val;
              }
            })

            if(tallyRecord)
            {
              setRegId(emailRecord.id)
    
              let countryName = "";
              if(emailRecord.country === "srilanka"){
                countryName = "Sri Lanka"
              }
              else if(emailRecord.country === "brunei"){
                countryName = "Brunei"
              }
              else if(emailRecord.country === "nepal"){
                countryName = "Nepal"
              }
              else if(emailRecord.country === "myanmar"){
                countryName = "Myanmar"
              }
              setRegCountry(countryName)
              setRegfullname(emailRecord.full_name)
      
              if(emailRecord.status === "pending_verification"){
                setRegStatus("Pending Verification")
              }
              else if(emailRecord.status === "verified"){
                setRegStatus("Verified")
              }
              
              setRegEWExpiredDate(moment(emailRecord.purchase_date, "YYYY-MM-DD").add(2, "years").format("YYYY-MM-DD"))
              setRegEWCoverDate(moment(emailRecord.purchase_date, "YYYY-MM-DD").add(1, "years").format("YYYY-MM-DD"))
      
              setRegSCExpiredDate(moment(emailRecord.purchase_date, "YYYY-MM-DD").add(1, "years").format("YYYY-MM-DD"))
              setRegSCCoverDate(moment(emailRecord.purchase_date, "YYYY-MM-DD").format("YYYY-MM-DD"))
              setOpen(true)
              setLoading(false)
            }
            else
            {
              setEmailError(false)
              setImeiError(true)
              setLoading(false)
            }
            
            
          }
          else{
            setEmailError(false)
            setImeiError(true)
            setLoading(false)
          }
    
        }
      })
      
    }


    

  };


  return (
    <>
      <Helmet>
        <title> Search Extended Warranty Registration | Genxt Services </title>
      </Helmet>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Your Information"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              <Grid style={{ width: "100%", display: "flex",
            flexDirection: "column" }}>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              Register ID
              </Typography>
              <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
              {regId}
              </Typography>
              <Typography align='left' style={{ fontSize: 14,marginTop: 15 }}>
              Full Name
              </Typography>
              <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
              {regfullname}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              Country
              </Typography>
              <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
              {regCountry}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              Extended Warranty Covered Date
              </Typography>
              <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
              {regEWCoverDate}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              Extended Warranty Expired Date
              </Typography>
              <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
              {regEWExpiredDate}
              </Typography>

              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              Screen Replacement Covered Date
              </Typography>
              <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
              {regSCCoverDate}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              Screen Replacement Expired Date
              </Typography>
              <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
              {regSCExpiredDate}
              </Typography>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Grid style={{ width: "100%", display: "flex", alignItems: "center",  flexDirection: "column"  }}>


        <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25,  flexDirection: "column", alignItems: "center" }}>
          
        

          <Typography style={{ fontWeight: 700, fontSize: 30, }}>
          Search Your Extended Warranty Registration
            </Typography>

            <FormControl fullWidth sx={{ mt: 3 }}>
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={category}
                label="Category"
                onChange={handleChangeCategory}
              >
                <MenuItem value={"iphone"}>iPhone</MenuItem>
                <MenuItem value={"mac"}>Mac</MenuItem>
                <MenuItem value={"ipad"}>iPad</MenuItem>
              </Select>
            </FormControl>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ display: "flex", width: "100%", flexDirection: "column", alignItems: "center" }}>
              <TextField
                style={{ marginTop: 20, width: "100%" }}
                id="imeiNo"
                label={"IMEI Number"}
                type='number'
                name="imeiNo"
                required
                error={imeiError}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              />
              {imeiError ? <Typography style={{ marginTop: 10 , fontSize: 11, color: 'red' }}>
          IMEI Number Not Found.
          </Typography> : null }

              <TextField
                style={{ marginTop: 20, width: "100%" }}
                id="email"
                name="email"
                required
                type="email"
                error={emailError}
                autoComplete="off"
                label={"Email Address"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              />

{emailError ? <Typography style={{ marginTop: 10 , fontSize: 11, color: 'red' }}>
          Email Not Found.
          </Typography> : null }

              {loading ?<CircularProgress sx={{ mt: 3, mb: 2 }} color="inherit" />:
              <Button
                type="submit"
                variant="contained"
                disableElevation
                sx={{ mt: 3, mb: 2 }}
              >
                 Search Registration
              </Button>}
            </Box>
            
        </Grid>
        

       
        
      </Grid>
    </>
  );
}
