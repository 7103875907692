import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';

const iconStyle = {
  width: 48,
  height: 48,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'warning.main',
  mr: 1,
  '&:hover': {
    bgcolor: 'warning.dark',
  },
};

const LANGUAGES = [
  {
    code: 'en-US',
    name: 'English',
  },
  {
    code: 'fr-FR',
    name: 'Français',
  },
];

export default function AppFooter() {
  return (
    <Typography
      component="footer"
      sx={{ display: 'flex' }}
    >
      <Container sx={{ my: 3, display: 'flex'  }}>
       
        <Grid container style={{ display: 'flex', justifyContent: "space-between" }}>
          <Grid item>
            <Typography variant="caption">
            Copyright © 2023 Genxt Services. All Rights Reserved. 
            </Typography>
          </Grid>
          <Grid item>
          <Typography variant="caption">
            Singapore
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}
